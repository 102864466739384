<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import printCardHeader from "./printCard-header";
  import {
    getZkzInfo,
    // clearZkz
    decompZkzImg
  } from "@/api/printCard/printCard.js"
  import {
    getUploadLimit,
    checkFile,
    uploadFiles,
  } from "@/api/common/public.js";
  /**
   * 准考证库导入--数据上传及匹配

   */
  export default {
    components: {
      Layout,
      CheckHeader,
      printCardHeader,
    },
    data() {
      return {
        title: "准考证库导入 ",
        title2: "",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "准考证库导入",
            active: true
          }
        ],
        module: "ZKZK",
        importTitle: "考试准考证库导入",
        excelForm: {
          '姓名': 'xm',
          '性别': 'xb'
        },
        sid: "",
        addForm: {
          uploadTime: ""
        },
        ksbmbh: "",
        uploadLimit: {
          ext: "",
          size: 0
        },
        uploadModule: "RarPhoto",
        uploadStatus: "",
        fileId: "",
        fileUrl: "",
        fileName: ""
      };

    },

    methods: {
      // 获取详情
      getDeatails(sid) {
        getZkzInfo(sid).then(res => {
          if (res.status) {
            this.addForm = res.data
            this.ksbmbh = res.data.ksbmbh
            this.title2 = res.data.ksmc ? res.data.ksmc : ""
          }
        })

      },
      // 完成匹配跳转
      submit() {
        this.$refs.header.getUrl('/admin/printCardImportTime')
      },


      // 准考证库上传
      uploadZkzk() {
        var _this = this;
        _this.$importModal().show({
          title: _this.importTitle,
          module: _this.module,
          ksbmbh: _this.ksbmbh,
          zkzkid: _this.sid,
          success: _this.success
        })
      },
      success() {
        this.getDeatails(this.sid)
      },
      /* clearZkz() { //清空准考证库
        this.$confirm('此操作将清空当前准考证库的所有数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          clearZkz(this.sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '当前准考证库清空成功!'
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消清空'
          });
        });
      } */
      uploadFile(file) {
        /** 上传照片文件 */
        var _this = this;
        file = file.raw;
        if (checkFile(_this, this.uploadLimit.size, this.uploadLimit.ext, file)) {
          _this.uploadStatus = "loading";
          uploadFiles(_this.uploadModule, file).then(res => {
            if (res.status) {
              var file = res.data[0];
              if (file.status) {
                this.fileId = file.fileId;
                this.fileName = file.name
                this.fileUrl = file.path
                _this.$message({
                  type: "success",
                  message: "照片上传成功"
                });
                decompZkzImg(this.fileId, this.sid).then(res => {
                  if (res.status) {
                    _this.$message({
                      type: "success",
                      message: "照片解压成功"
                    });
                  }
                })
              } else {
                _this.$message({
                  type: "error",
                  message: file.error
                });
              }
            }
            _this.uploadStatus = "";
          });
        }
      },
      getFileLimit() { // 获取文件上传限制
        getUploadLimit({
          module: this.uploadModule
        }).then(res => {
          if (res.status) {
            this.uploadLimit["ext"] = "." + res.data.extLimit.replaceAll(",", ",.");
            this.uploadLimit["size"] = res.data.sizeLimit;
          }
        });
      },

    },

    mounted() {
      if (this.sid) {
        this.getDeatails(this.sid)
        this.getFileLimit()
      }
    },
    created() {
      this.sid = this.$route.query["sid"] ? this.$route.query["sid"] : "";

    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="title2" :items="items" />
    <printCardHeader ref="header" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 460px;">
          <div class="card-body">
            <h4 class="card-title">请上传准考证库并对导入字段进行匹配对应</h4>
            <div class="outerCotent mt-3 ">
              <div class="outerCotent mt-3 ">
                <div class="msgContent flexList fs-xs">
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgName ">上传库结构
                    </div>
                    <div class="msgVal selectShow flexList">
                      <a :href="this.API_URL + '/yethan/register/downloadTemplate/' + this.module  + '?ytoken=' + this.getStore(
                      'accessToken')" target="_blank">
                        <div class="blue-font">
                          点击下载准考证上传库结构模板.dbf</div>
                      </a>


                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgName ">字段说明</div>
                    <div class="msgVal selectShow  flexList w-33">
                      <a :href="this.API_URL + '/yethan/ticketBankRecord/downFieldExplain' + '?ytoken=' + this.getStore(
          'accessToken')" target="_blank">
                        <div class="blue-font">
                          点击下载准考证上传库字段说明.xlsx</div>
                      </a>

                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel">
                    <div class="msgName ">准考证库上传</div>
                    <div class="msgVal selectShow flexList">
                      <el-button size="small" type="primary" plain class="flexList mr-2 h30  " @click="uploadZkzk"><i
                          class="iconfont icon-riLine-upload-2-line mr-2"></i>准考证库上传</el-button>
                      <span class="mr-3">已上传：<span
                          class="text-info">{{addForm.uploadSize?addForm.uploadSize:0}}</span>条记录</span>
                      <span class="mr-3">导入人：{{addForm.uploadUser}}</span>
                      <span class="mr-3">导入时间：{{addForm.uploadTime}}</span>
                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel" v-if="!addForm.ksbmbh">
                    <div class="msgName ">照片上传</div>
                    <div class="msgVal selectShow flexList">
                      <!-- <span v-if="fileUrl" class="colorA mr-2" style="display:block;">{{fileName}}
                      </span> -->
                      <el-upload class="upload-demo mr-2" ref="upload" action="" :on-change="uploadFile"
                        :show-file-list="false" :accept="uploadLimit.ext" :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">
                          <span name="default" v-if="uploadStatus != 'loading'" style="font-size: 0.7rem;">上传照片</span>
                          <span v-if="uploadStatus == 'loading'">上传中<i class="el-icon-loading"></i></span>
                        </el-button>
                        <el-tag type="danger" style="margin-left: 10px;">
                          支持上传{{ uploadLimit.ext }}文件，且不超过{{ uploadLimit.size/1024/1024 }}M</el-tag>
                      </el-upload>
                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel">
                    <div class="msgName ">导入注意</div>
                    <div class="msgVal selectShow flexList text-danger">
                      <span class="mr-2"> ksrq(考试日期)格式为 "yyyy-MM-dd" 或 "yyyy年MM月dd日",</span>
                      <span>kssj(考试时间)前两位为24小时制例："13:30" 或 "15时30分" </span>
                    </div>
                  </div>
                  <!-- <div class="flexList w-100 msgLabel">
                    <div class="msgName ">清空准考证库</div>
                    <div class="msgVal selectShow flexList">
                      <el-button size="small" type="primary" plain class="flexList mr-2 h30  " @click="clearZkz">清空准考证库
                      </el-button>
                    </div>
                  </div> -->
                  <div class="flexList w-100 msgLabel" style="height: 60px;">
                    <div class="msgName"></div>
                    <div class="msgVal flexList">
                      <button class="btn btn-info h35 mr-2 sub-btn" @click="submit">已完成字段匹配，下一步</button>
                      <a href="javascript:history.back()"><button class="btn btn-secondary h35 mr-2 ">返回上一步</button></a>
                    </div>
                  </div>
                </div>

              </div>
              <!--  <importFields module="abc" :excelFields='["姓名", "性别"]' v-model="excelForm" :isImport="false" class="mb-2">
              </importFields>
              <exportFields module="abc" :excelFields='["姓名", "性别"]' v-model="excelForm" :isImport="false" class="mb-2">
              </exportFields> -->
              <!-- <div class="flexList w-100 msgLabel" style="height: 60px; border-bottom: 1px solid #DFDFDF;">
                <div class="msgVal col-lg-4">{{excelForm}}</div>
                <div class="msgVal flexList">
                  <a href="/admin/scoreImportTime"><button
                      class="btn btn-info h35 mr-2 sub-btn">已完成字段匹配，下一步</button></a>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->

    <!-- 弹窗结束 -->
  </Layout>
</template>
